var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "not-found page" },
    [
      _c("AtomLogo"),
      _c("span", { staticClass: "message" }, [
        _vm._v("The resource that you are looking for could not be found."),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }