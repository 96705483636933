<template>
  <div class="not-found page">
    <AtomLogo />
    <span class="message"
      >The resource that you are looking for could not be found.</span
    >
  </div>
</template>

<script>
import AtomLogo from "../../components/atoms/common/AtomLogo";
export default {
  name: "NotFoundPage",
  components: { AtomLogo },
  mounted() {
    if (this.$store.state.base.documentIsReady) {
      const base = this.$store.getters.constants.base;
      this.$store.dispatch(
        base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
        false
      );
    }
  },
};
</script>

<style lang="scss">
.page {
  &.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: #c5c5c5;
    .atom-logo {
      img {
        max-width: 300px;
      }
    }
    .message {
      font-size: 16px;
      display: block;
      margin-top: 30px;
      padding: 0 25px;
    }
  }
}
</style>
